.header-text {
    text-decoration: none;
    color: black;
}

.raj-bar {
    font-size: 5vw;
}

.raj-bar-mobile {
    font-size: 10vw;
}

.other-text {
    color: #484848;
}

.main-text {
    color: black;
    font-weight: bold;
}

.info-text-select {
    cursor: pointer;
    margin-top: 50px;
    margin-bottom: 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-text {
    margin-top: 50px;
    margin-bottom: 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.table-left {
    padding-right: 20px;
}

.table-right {
    padding-left: 20px;
}

.link-button {
    cursor: pointer;
    border: 1px;
    border-radius: 5px;
    padding: 10px;
    width: 64px;
    margin: 2px auto;
}

.link-button:hover {
    cursor: pointer;
    border: 1px;
    border-radius: 5px;
    padding: 10px;
    width: 64px;
    margin: 2px auto;
    background: darkgray;
}

.link-button-selected {
    cursor: pointer;
    border: 1px;
    border-radius: 5px;
    padding: 10px;
    width: 64px;
    background: slategrey;
    color: white;
    margin: 2px auto;
}

.my-tooltip {
    padding: 2px 5px !important;
}